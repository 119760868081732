import request, { type Variables } from "graphql-request";
import { useAuth0 } from "@auth0/auth0-vue";
import { type MaybeRef, unref } from "vue";
import {
  type QueryKey,
  useQuery,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";

export function useGraphQLQuery<
  TResult,
  TVariables,
  TQueryKey extends QueryKey
>(
  document: TypedDocumentNode<TResult, TVariables>,
  variables?: TVariables extends Record<string, never>
    ? never
    : MaybeRef<TVariables>,
  options: Omit<
    UseQueryOptions<TResult, unknown, TResult, TQueryKey>,
    "queryFn" | "queryKey"
  > = {}
): UseQueryReturnType<TResult, unknown> & { queryKey: QueryKey } {
  const auth0 = useAuth0();

  const queryKey = [
    (document.definitions[0] as any).name.value,
    variables,
  ] as QueryKey;

  const query = useQuery({
    ...(options as unknown[]),
    queryKey: queryKey as any,
    queryFn: async ({ queryKey }) => {
      const accessToken = await auth0.getAccessTokenSilently({
        audience: import.meta.env.VITE_AUTH_AUDIENCE,
      });

      return request(
        import.meta.env.VITE_API_URL as string,
        document,
        unref(queryKey[1]) ? (unref(queryKey[1]) as Variables) : undefined,
        {
          authorization: `Bearer ${accessToken}`,
        }
      );
    },
  });

  return {
    ...query,
    queryKey,
  };
}
