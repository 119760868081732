<template>
  <SeminarSkeletonTable v-if="isLoading && !seminars" />
  <SeminarTable
    v-else-if="seminars"
    :seminars="seminars"
    :upcoming-seminars-checkbox="upcomingSeminarsCheckbox"
    @toggle-upcoming-seminars-checkbox="
      upcomingSeminarsCheckbox = !upcomingSeminarsCheckbox
    "
    @delete-seminar="deleteSeminar"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import SeminarTable from "@/components/seminar/SeminarTable/SeminarTable.vue";
import SeminarSkeletonTable from "@/components/skeleton/TableSkeleton.vue";
import { useQuasar } from "quasar";
import type { Seminar } from "@/components/seminar/SeminarTable/types";
import { useGraphQLMutation, useGraphQLQuery } from "@/api/graphql/adapters";
import { GetSeminarsQueryDocument } from "@/api/graphql/documents/GetSeminars";
import { DeleteSeminarMutationDocument } from "@/api/graphql/documents/DeleteSeminar";
import type { GetSeminarsQueryVariables } from "@/api/graphql/codegen/graphql";
import { useQueryClient } from "@tanstack/vue-query";

const $q = useQuasar();
const queryClient = useQueryClient();

const upcomingSeminarsCheckbox = ref(true);
const seminarsQueryVariables = computed<GetSeminarsQueryVariables>(() => {
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
  return {
    seminarsInput: {
      fromDate: upcomingSeminarsCheckbox.value
        ? todayMidnight.toString()
        : undefined,
    },
  };
});

const {
  data: result,
  isLoading,
  queryKey,
} = useGraphQLQuery(GetSeminarsQueryDocument, seminarsQueryVariables);

const { mutateAsync: deleteSeminarMutation } = useGraphQLMutation(
  DeleteSeminarMutationDocument
);

const deleteSeminar = (seminar: Seminar) => {
  // ALERT: There is intended XSS vulnerability here. Only for demonstration purposes.
  // if you rename seminar to `<img src onerror="alert(1)" >` and then you try to delete it, you will get an alert window.
  // Btw this is not working wit <script> tag, since it is run only on page load. https://www.youtube.com/watch?v=ns1LX6mEvyM

  $q.dialog({
    title: "Potvrzení smazání semináře",
    message: `Opravdu chcete smazat seminář <strong>${seminar.name}</strong>? Seminář smaže také všechny jeho účastníky. Tato akce nelze vzít zpátky!`,
    html: true,
    color: "negative",
    ok: `Ano`,
    cancel: true,
  }).onOk(async () => {
    await deleteSeminarMutation({ seminar: { id: seminar.id } });
    await queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  });
};

const seminars = computed(() => result.value?.seminars);
</script>
