<template>
  <q-layout view="lHh Lpr lFf">
    <Toolbar />
    <q-page-container
      class="q-mx-auto q-mt-md q-mb-xl q-px-sm"
      style="max-width: 90%"
    >
      <div v-if="isLoading" />
      <LoginView v-else-if="!isAuthenticated" />
      <RouterView v-else />
    </q-page-container>

    <Footer />
    <VueQueryDevtools />
  </q-layout>
</template>

<script lang="ts" setup>
import Toolbar from "@/components/toolbar/Toolbar.vue";
import LoginView from "@/views/LoginView.vue";
import { useQuasar } from "quasar";
import { watch } from "vue";
import { useAuthenticatedUser } from "@/stores/auth";
import { useAuth0 } from "@auth0/auth0-vue";
import Footer from "@/components/footer/Footer.vue";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

const $q = useQuasar();
const { isAuthenticated, isLoading } = useAuthenticatedUser();
const auth0 = useAuth0();

watch(auth0.error, (e) => {
  auth0.logout({
    returnTo: `${window.location.origin}?err=${encodeURIComponent(e.message)}`,
  });
});

watch(
  () => isLoading.value,
  () => {
    if (isLoading.value) {
      $q.loading.show({
        message: "Načítání...",
      });
    } else {
      $q.loading.hide();
    }
  },
  { immediate: true }
);
</script>
